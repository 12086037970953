<template>
  <section class="request-details">
    <Section>
      <section class="request-details__block">
        <Headline size="4">Activation Requested</Headline>
        <InputLayout label="Status">
          <span class="request-details__block-text">{{ getFromRequest('status') }}</span>
        </InputLayout>
      </section>

      <section class="request-details__block">
        <Headline size="4">Match Information</Headline>
        <InputLayout label="Tournament">
          <span class="request-details__block-text">{{ getFromRequest('match.matchday.stage.season.competition.name') }}</span>
        </InputLayout>
        <InputLayout label="Matchday">
          <span class="request-details__block-text">{{ getFromRequest('match.matchday.optionLabel') }}</span>
        </InputLayout>
        <InputLayout label="Match">
          <span class="request-details__block-text">{{ getFromRequest('match.name') }}</span>
        </InputLayout>
        <InputLayout label="Venue">
          <span class="request-details__block-text">{{ getFromRequest('match.venue.localTitle') }}</span>
        </InputLayout>
        <InputLayout label="Match Kick-off Time (UTC+8)">
          <span class="request-details__block-text">{{  getFromRequest('match.startDateTime') ? this.getMatchKickoffTime(getFromRequest('match.startDateTime')) : '' }}</span>
        </InputLayout>
      </section>

      <section class="request-details__block">
        <Headline size="4">Organisation Information</Headline>
        <InputLayout label="Category">
          <span class="request-details__block-text request-details__capitalize">{{ getFromRequest('organization.categories[0].name') }}</span>
        </InputLayout>
        <InputLayout label="Organisation name">
          <span class="request-details__block-text request-details__capitalize">{{ getFromRequest('organization.name') }}</span>
        </InputLayout>
        <InputLayout label="Requested by User">
          <span class="request-details__block-text">{{ getFromRequest('bookedBy.name') }}</span>
        </InputLayout>
      </section>

      <section class="request-details__block">
        <Headline size="4">Activation & Contact person details</Headline>
        <InputLayout label="Activation name">
          <span class="request-details__block-text">{{ getFromRequest('activity.name') }}</span>
        </InputLayout>
        <InputLayout label="First name">
          <span class="request-details__block-text">{{ getFromRequest('contactFirstName') }}</span>
        </InputLayout>
        <InputLayout label="Last name">
          <span class="request-details__block-text">{{ getFromRequest('contactLastName') }}</span>
        </InputLayout>
        <InputLayout label="Email">
          <span class="request-details__block-text request-details__capitalize">{{ getFromRequest('contactEmail') }}</span>
        </InputLayout>
        <InputLayout label="Mobile">
          <span class="request-details__block-text request-details__capitalize">{{ getFromRequest('contactPhone') }}</span>
        </InputLayout>

        <InputLayout
          v-for="(item, index) in getFromRequest('bookedSubcategories')"
          :label="item.subcategory ? item.subcategory.name : '--'"
          :key="index"
        >
          <span class="request-details__block-text request-details__capitalize">{{ item.amount }}</span>
        </InputLayout>
      </section>

      <section class="request-details__block" v-if="getFromRequest('hasConcept') === true">
        <Headline size="4">Activation Concept</Headline>
        <span>Activation concept file has been uploaded</span>
      </section>
    </Section>
    <div class="btn-wrapper">
      <Button
          type="primary"
          @click="navigateToOverview()"
      >
        Back
      </Button>
      <Button
          type="danger"
          class="delete-button"
          @click="deleteSponsorRequest()"
      >
        Delete
      </Button>
    </div>
    <vue-confirm-dialog class="confirm-dialog"></vue-confirm-dialog>
  </section>
</template>

<script>
import Headline from '@/components/Headline/Headline.component';
import InputLayout from '@/components/Inputs/components/InputLayout.component';
import Section from '@/components/Section/Section.component';
import Button from "@/components/Button/Button.component";
import moment from "moment";

import get from "lodash.get";
import RouterNames from "@/router/route.names";
import {DtoApiDeleteActivationRequest} from "../../../../classes/dto/api/sponsor/dto.api.delete-activation.request";


export default {
  components: {
    Headline,
    InputLayout,
    Section,
    Button
  },
  data: () => ({}),
  computed: {
    activationDetails() { return get(this, '$store.state.sponsorActivationsList.activationDetails') },
  },
  methods: {
    async deleteSponsorRequest() {
      this.$confirm(
          {
            message: `Are you sure you want to delete this activation?`,
            button: {
              no: 'No',
              yes: 'Yes'
            },
            callback: confirm => {
              if (confirm) {
                new DtoApiDeleteActivationRequest().$deleteActivation(this.getFromRequest('bookingId'), this.$store.state.user.account.id).then(() => {
                  this.navigateToOverview()
                })
              }
            }
          })
    },
    navigateToOverview() {
      const name = RouterNames.IndexRoutes.DashboardRoutes.SponsorActivationRoutes.SponsorActivationList;
      this.$router.push({name});
    },
    moment,
    getFromRequest(path) {
      const data = get(this, `activationDetails.${path}`, )
      return data ? data : '--'
    },
    getSingleActivation(data) { this.$store.dispatch('sponsorActivationsList/getSingleActivation', data) },
    checkPermissions() {
      const permitted = this.$store.getters['user/haveAccessToActivations'];
      if (!permitted) this.$router.push({name: RouterNames.Home})
    },
    getMatchKickoffTime(startDateTime) {
      return moment(startDateTime).add(8, 'hours').format('DD.MM.YYYY HH:mm')
    },
  },
  created() {
    this.checkPermissions()
    const accreditationId = this.$route.params.request
    this.getSingleActivation(accreditationId)
  }
}
</script>

<style lang="scss">
@import "../../../../styles/mixins/ellipsis.mixin";

.request-details {
  .delete-button {
    margin-left: 20px;
  }
  &__block {
    margin-bottom: 50px;
  }
  &__section,
  &__comments {
    margin-top: 60px;
  }

  .headline-component {
    margin-top: 0 !important;
    margin-bottom: 10px;
  }

  .InputLayout {
    margin-bottom: 10px;
  }

  .InputLabel__label {
    @include ellipsis;
    $width: 250px;
    overflow: visible;
    min-width: $width;
    max-width: $width;
    width: $width;
    font-weight: 400;
    font-size: 14px;
  }

  &__passport-photo {
    max-width: 220px;
  }

  &__capitalize {
    text-transform: capitalize;
  }
}
</style>
